/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a,
a:visited,
a:link,
a:active,
a:hover {
  color: #282c34 !important;
  border-radius: 5px;
}

a:hover {
  text-decoration: underline;
}

body {
  margin: auto;
  max-width: 50%;
  min-width: 500px;
} */

html,
body {
  height: 100vh;
  min-height: 500px;
  padding: 0 1vw;
}

main {
  height: max(80vh, 400px);
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 1vh 10vw;
}

button {
  color: navy;
  background-color: white;
  border: none;
  text-decoration: underline;
}

.btn-amount {
  color: #117800;
  background-color: #e4ffdf;
  text-decoration: none;
  font-weight: bold;
  height: 3rem;
  border: 1px solid #d5f5d0;
  border-radius: 1rem;
}

.badge-success {
  color: #117800;
  background-color: #e4ffdf;
}

.badge-danger {
  color: #780000;
  background-color: #ffdfdf;
}

a {
  color: navy;
}
